/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
import styled from 'styled-components/native';
import React, {
  useState,
  useCallback,
  ReactNode,
  useMemo,
  useEffect,
} from 'react';
import Spinner from 'atoms/spinner';
import FastImage from 'react-native-fast-image';

import {
  Linking, Platform, ScrollView, StyleSheet,
} from 'react-native';
import { connect, useSelector } from 'react-redux';
import queryString from 'query-string';
import openInAppBrowser from 'domain/shared/utils/openInAppBrowser';
import { useNavigation, useRoute } from '@react-navigation/native';
import PreviewHeader from 'components/molecules/preview-header';
import { Flex } from '@kanvas/andromeda';
import ActionService from 'domain/shared/services/action-service';
import { showError, showWarning } from 'domain/shared/utils/toast';
import Toast from 'organisms/toast';
import ShareModal, { ContactData } from 'components/organisms/share-modal';
import {
  IS_CHROME_EXTENSION,
  IS_DESKTOP_WEB,
  IS_IOS_WEB_DEVICE,
  IS_WEB,
} from 'domain/shared/constants';
import CXPreviewActions from 'components/molecules/CXPreviewActions';
import Colors from 'theme/colors';
import { ActivityListEvents, emitActivityListEvent } from 'domain/shared/events/activities-list';
import { sendAmplitudeEvent } from 'domain/shared/utils/amplitude';
import { OPENING_ACTION } from 'domain/shared/types/amplitude-event';
import {
  COPY_TEXT,
  EMAIL_SHARE,
  IN_APP_OPEN,
  TEXT_SHARE,
} from 'domain/shared/types/amplitude-actions';
import useForms from 'domain/shared/hooks/useForms';
import { Header3 } from 'components/molecules/text';
import { translate } from 'locales';
import { openLinkOnNewTab } from 'domain/shared/utils/openOnNewTab';
import { TextTransform } from 'react-native-localized-text';
import ViewInventoryStack from 'domain/view-inventory';
import CRMExtensionService, { CRMMessageTool } from 'domain/shared/services/crm-extension-service';
import BundleList from 'components/organisms/bundle-list';
import { Bundle } from 'domain/opportunity-wall/types/bundle.interface';
import { EventRegister } from 'react-native-event-listeners';
import { CLOSE_EXPANDED_CHECK_LIST } from 'components/organisms/check-list';
import { ProductVariant } from 'domain/shared/types/product-variants';
import getDefaultProductChannel from 'domain/shared/utils/getDefaultProductChannel';
import { RootState } from 'domain/shared/store';

import useActionMessage from 'domain/opportunity-wall/hooks/use-action-message';
import useDocList from 'domain/opportunity-wall/hooks/use-doc-list';
import CustomFieldsApi from 'domain/shared/services/custom-fields';
import { IPeople } from 'domain/shared/types/people';
import { CompanySettings } from 'domain/shared/types/settings';

import { ActionState, ContentPreviewContext } from './content-preview-context';
import ContentMultiSelect from './ContentMultiSelect';
import FileSharing, { FileSharingValue } from './file-sharing';
import VehicleOrder from './vehicle-order';
import ShareCode from './share-code';

const MB = Platform.OS === 'android' ? 80 : 95;
const PREFILL_SIGN_DOCS = 'prefill-sign-docs';

const styles = StyleSheet.create({
  contentContainerStyle: { marginBottom: MB + 10 },
});

const PreviewImage = styled(FastImage)`
  flex: 1;
  shadow-color: ${Colors.BLACK};
  shadow-opacity: 0.20;
  shadow-offset: 0 5px;
  border-radius: 16px;
  border-color: ${Colors.DIVIDER};
  border-width: 1px;
  background-color: ${Colors.WHITE};
  overflow: hidden;
  margin-bottom: ${MB}px;
  ${IS_WEB ? `box-shadow: 0px 3px 5px ${Colors.SHADOW}` : ''};
`;

const Container = styled(Flex)`
  background-color: ${Colors.WHITE};
`;

const Background = styled(Flex)`
  background-color: ${Colors.WHITE};
  flex: 1;
`;

function ListContainer(props: { bundles: any[], children: ReactNode }) {
  if (!props.bundles.length) return <>{props.children}</>;
  return <ScrollView style={styles.contentContainerStyle}>{props.children}</ScrollView>;
}

// TODO: refactor component to improve redability 
const ContentPreview = ({ defaultLanguage, loggedUser, activeRooftop }: any) => {
  const { params: { props } = {} as any } = useRoute();
  const navigation = useNavigation() as any;
  const {
    title = '',
    key = '',
    contact = {},
    reasonEn = '',
    reasonEs = '',
    extraLinkParams = {},
    multiSelect,
    bankSelectedItem = {},
    description = '',
    should_open_in_browser = false,
    id = 0,
    form_config = {},
    selected = null,
  } = props;

  // const [content, setContent] = useState<any>({});
  const [lang] = useState(defaultLanguage);
  
  const [amountReferral] = useState('');
  const [visible, setVisible] = useState(false);
  const [actionState, setActionState] = useState<ActionState>({ title, toolbarRight: null });
  const { forms: currentForm, setForms }: any = useForms();
  const activeOpportunity = useSelector((state: RootState) => state.opportunity.activeOpportunity)!;
  const [selectedProducts, setSelectedProducts] = useState<ProductVariant[]>([]);

  const [fileSharing, setFileSharing] = useState<FileSharingValue | undefined>(undefined);

  const [bundle, setBundle] = useState<Bundle | null>(null);
  const [isOpenLoading, setIsOpenLoading] = useState(false);
  const [isShareLoading, setIsShareLoading] = useState(false);
  const [shareCodeValue, setShareCodeValue] = useState('');

  const companySettings = useSelector(({ settings }: RootState) => settings.companySettings) as CompanySettings;
  const { pure_cars_id: pureCarsId, NO_REWARD_GET_REFERRAL, NEED_ANALYSIS_FORM: needsAnalysisForm } = companySettings;
  
  const updateActionState = useCallback((state: ActionState) => {
    setActionState((old) => ({
      ...old,
      ...state,
    }));
  }, []);

  const contentPreviewContextValue = useMemo(() => ({
    setActionState: updateActionState,
    setSelectedProducts,
    leadUUID: contact?.uuid,
  }), [contact.uuid]);

  const { bundles, items, loading, selectedValue, setSelectedValue } = useDocList({ 
    action: key,
    onError: (error: any) => {
      showError(error.message);
    },
    onNoData: (error: string) => {
      showWarning(error);
      navigation.goBack();
    }
  });

  useEffect(() => {
    if (bundle) return;
    if (!items) return;
    if (selectedValue.length > 0) return;
    if (loading) return;
    if (items.length <= 0) return;

    const item = items.find(i => i.value == selected);
    if (item) setSelectedValue([item]);
  }, [items, loading, selectedValue, bundle])

  const isSingleShareProduct = key === 'i-packet' || key === 'pure-cars';
  const isPureCars = key === 'pure-cars';
  const isShareProduct = key === 'view-vehicle';
  const isNeedsAnalysis = key === 'needs-analysis';
  const isFileSharing = key === 'file-sharing'
  const isSignDocs = key === 'esign-docs' || key === 'purchase-vehicle' || key === 'finance-and-insurance';
  const isVehicleOrder = key === 'vehicle-order';
  const isShareCode = key === 'share-code' || key === 'share-electrify-america';

  /* eslint-disable no-param-reassign */
  const peopleList = contact?.participants.map((people: IPeople) => ({
    ...people,
    participant: true,
  }));

  const contacts = [contact.people, ...peopleList];

  const { message: content, loading: loadingMessage } = useActionMessage({
    id,
    action: key,
    extraLinkParams,
    bankSelectedItem,
    amountReferral,
    contact: contact,
    onFail: () => {
      showError('There is currently no Dealer Content available to be shared');
      navigation.goBack();
    }
  });

  const formatedMessage = useMemo(() => {
    if (loadingMessage) return;

    let message = '';

    const { message_content } = content || {};
    const { ENG = '', ES = '' } = message_content || {};

    if (defaultLanguage === 'en' || defaultLanguage === 'both') {
      message = `${ENG}\n${content.url ? content.url : content.link}`;
    } else if (defaultLanguage === 'sp') {
      message = `${ES}\n${content.url ? content.url : content.link}`;
    }

    if (key !== 'get-referrals') return message;
    return message.replace('referralAmount', `$${amountReferral}`);
  }, [
    key,
    content,
    loadingMessage,
    defaultLanguage,
  ]);

  const extraContent = useMemo(() => {
    let extraContent = {} as any;

    if (!bundle && multiSelect && selectedValue.length > 0) {
      extraContent = selectedValue.map((item: any) => item.value);
    }

    if (bundle) {
      extraContent = bundle.files.map((file) => file.id);
    }

    if (key === 'search-hub' || ('payoff-form' && extraLinkParams.length > 0)) {
      extraContent.bank = bankSelectedItem;
    }

    const { slug } = selectedProducts?.[0] || {};

    if (key === 'i-packet') {
      extraContent.url = `https://www.ipacket.info/${slug}`;
    }

    if (isPureCars) {
      extraContent.url = `https://app.purecars.com/ValueReport2?dealerId=${pureCarsId}&vin=${slug}`;
    }

    if (isFileSharing || isVehicleOrder) {
      extraContent = fileSharing;
    }

    if (key === 'get-referrals') {
      extraContent.state = NO_REWARD_GET_REFERRAL;
    }

    if (isShareCode) {
      extraContent.code = shareCodeValue;
    }

    return extraContent;
  }, [bundle, multiSelect, selectedProducts, bankSelectedItem, pureCarsId, fileSharing, NO_REWARD_GET_REFERRAL, shareCodeValue]);

  const onShare = () => {
    const { people } = contact;
    try {
      if (multiSelect && selectedValue.length === 0 && bundle === null) {
        showWarning(translate('selectAtLeastOneFile', TextTransform.CAPITAL));
        return;
      }
      if (isShareProduct && selectedProducts.length === 0) {
        showWarning(translate('selectAtLeastOneProduct', TextTransform.CAPITAL));
        return;
      }
      if (people?.phone || people.email) {
        setVisible(true);
      } else {
        showError('Please validate contact information, email and phone number');
        return;
      }
    } catch (error) {
      showError('Please validate contact information, email and phone number');
    }
  };

  const openActionInBrowser = async (link: string) => {
    sendAmplitudeEvent(IN_APP_OPEN, { actionName: key, lead: contact.uuid });
    if (should_open_in_browser) {
      try {
        if (IS_WEB) {
          if (IS_CHROME_EXTENSION) {
            CRMExtensionService.openLinkOnModal(link, '');
          } else {
            await openLinkOnNewTab(link);
          }
        } else {
          await openInAppBrowser(link, title);
        }
      } catch (e) {
        await openInAppBrowser(link, title);
      }
    } else if (IS_WEB) {
      if (IS_CHROME_EXTENSION) {
        CRMExtensionService.openLinkOnModal(link, '');
      } else {
        openLinkOnNewTab(link);
      }
    } else {
      await openInAppBrowser(link, title);
    }
    navigation.goBack();
  };

  // imprive the complete process
  const postMessage = useCallback(async (callback?: Function, status: string = 'sent', shareViaP: string = '', contactId?: number) => {
    const channel = await getDefaultProductChannel();
    const product_id = selectedProducts.map((item) => item.uuid);
    let forms = {};

    // TODO: find a better way to implement this
    const docList = Object.keys(currentForm || {});

    if (isSignDocs && currentForm && docList.length > 0) {
      const { customFields } = await CustomFieldsApi.get({ entity_id: activeOpportunity.uuid, name: PREFILL_SIGN_DOCS });
      const prefill = customFields[PREFILL_SIGN_DOCS];

      for (const doc of docList) {
        forms = { ...forms, [doc]: { ...currentForm[doc], ...prefill} }
      }
    }
    const form_config = needsAnalysisForm ? JSON.stringify(needsAnalysisForm) : undefined;

    const { customFields } = await CustomFieldsApi.get({ entity_id: activeOpportunity.uuid, name: 'check_list_status' });

    let activeTaskListId: number = 0;

    if (customFields.check_list_status) {
      activeTaskListId = customFields.check_list_status.activeTaskListId
    }

    const data = {
      verb: key,
      leads_uuid: contact.uuid,
      message: {
        visitor_id: content?.params?.visitor_id,
        contact_uuid: content?.params?.contact_id,
        user_uuid: content?.params?.user_id,
        hashtagVisited: isShareProduct ? key : title, // action-type
        status,
        link_preview: content?.link_preview,
        link: content?.link,
        text: description,
        source: 'mobile',
        data: extraContent,
        preFill: forms,
        via: shareViaP,
        checklistId: activeTaskListId,
        ...isNeedsAnalysis ? { form_config } : {},
        ...isShareProduct ? { product_id, channel_id: channel?.uuid } : {},
        ...contactId ? { contact_participant: contactId } : {},
      },
    } as any;

    try {
      const uuid = contact.leads_receivers?.uuid || '0';
      await ActionService.postReceiversMessage(uuid, data);
      if (callback) {
        emitActivityListEvent(
          ActivityListEvents.ACTIVITY_LIST_RELOAD,
          null,
        );
        await callback();
      } else {
        navigation.goBack();
      }

      setForms([]);
    } catch (err: any) {
      navigation.goBack();
      console.log(err.response);
    } finally {
      setIsShareLoading(false);
      setIsOpenLoading(false);
    }
  }, [selectedValue, content, currentForm, selectedProducts, extraContent, activeOpportunity, isSignDocs]);

  const postActionMessage = useCallback((shareVia: string, contactId?: number) => {
    postMessage(
      () => navigation.goBack(),
      'sent',
      shareVia,
      contactId,
    );
  }, [postMessage]);

  // TODO: refactor | get content.
  const openShareOption = async (url: string, viaShare: string = '', contactId?: number) => {
    try {
      await Linking.openURL(url);
      postActionMessage(viaShare, contactId);
    } catch (error) {
      console.log(error);
    }
  };

   // TODO: refactor | get content.
  const onOpenInAppBrowser = async () => {
    CRMExtensionService.closeToggle();
    try {
      setIsOpenLoading(true);

      if (multiSelect && selectedValue.length === 0 && bundle === null) {
        showWarning(translate('selectAtLeastOneFile', TextTransform.CAPITAL));
        return;
      }

      if (isShareProduct && selectedProducts.length === 0) {
        showWarning(translate('selectAtLeastOneProduct', TextTransform.CAPITAL));
        return;
      }

      const queryParams = queryString.parseUrl(content.link_full || content.link);
      queryParams.query.ia = '1';

      const url = queryString.stringifyUrl(queryParams);
      const openBrowser = async () => {
        await postMessage(() => openActionInBrowser(url), 'sent', 'in-app');
      };

      if (key !== 'get-referrals') {
        await postMessage(openBrowser, 'sent');
        return;
      }
      await openBrowser();
    } catch (error) {
      setIsOpenLoading(false);
      showError('Please validate contact information, email and phone number');
    } finally {
      setIsOpenLoading(false);
    }
  };

  const getEmailSubject = () => {
    if (lang === 'sp') {
      return `${loggedUser.firstname} de ${activeRooftop.name} acaba de compartir un enlace contigo`;
    }
    return `${loggedUser.firstname} from ${activeRooftop.name} shared a link with you`;
  };

   // TODO: refactor | get content.
  const processSharing = (index: number, data?: ContactData) => {
    const { contact = '', name = '', id = undefined } = data || {};

    // eslint-disable-next-li= ne no-nested-ternary
    const share_by = index ==0
      ? TEXT_SHARE : index === 1 
        ? EMAIL_SHARE : COPY_TEXT;

    // eslint-disable-next-line no-nested-ternary
    const sharedVia = index === 0
      ? 'text' : index === 1 ? 'email' : 'copy';
    const shareViaTranslated = translate(sharedVia, TextTransform.NONE);

    const message = formatedMessage?.replace('{name}', name?.trim());
    const messageShareString = Platform.select({
      ios: () => `sms:/open?addresses=${contact}&body=${message}`,
      android: () => `sms:${contact}?body=${message}`,
      web: () => `sms:${contact}${IS_IOS_WEB_DEVICE ? '&' : '?'}body=${message}`,
    })?.();

    setIsShareLoading(true);

    const mobileSendMessage = (key: CRMMessageTool) =>{
      const participant = peopleList.find(({id}: any) => id === data?.id);
      CRMExtensionService.openCRMMessageTool(key, participant?.custom_fields?.VIN_SOLUTION_CONTACT, message!);
      postActionMessage(shareViaTranslated, id);
    }

    const sendMessage = (key: CRMMessageTool, messageShare: string) => {
      if (!IS_DESKTOP_WEB) {
        openShareOption(messageShare, shareViaTranslated, id);
        return;
      }

      mobileSendMessage(key);
    };

    const states: Record<number, VoidFunction> = {
      0: () => sendMessage(CRMMessageTool.Message, messageShareString!),
      1: () => sendMessage(CRMMessageTool.Email, `mailto:${contact}?subject=${getEmailSubject()}&body=${message}`),
      2: () => mobileSendMessage(CRMMessageTool.Copy),
    }

    states[index]?.();
    CRMExtensionService.closeToggle();
    setIsShareLoading(false);
    sendAmplitudeEvent(share_by, { event: OPENING_ACTION, actionName: key });
  };

  const shouldRenderPreviewActions = useCallback(() => {
    if (isFileSharing && !fileSharing) return false;
    if (isVehicleOrder && !fileSharing) return false;

    if (isSingleShareProduct && selectedProducts.length === 0) {
      return false;
    }

    if (isShareProduct && selectedProducts.length === 0) {
      return false;
    }
    return true;
  }, [
    isShareProduct,
    selectedProducts,
    isSingleShareProduct,
    isFileSharing,
    isVehicleOrder,
    fileSharing,
    isShareCode,
    shareCodeValue
  ]);

  const handleCheckList = (items: any) => {
    setBundle(null);
    setSelectedValue(items);
  };

  const handleBundle = (item: Bundle) => {
    setBundle(item);
    setSelectedValue([]);
    EventRegister.emit(CLOSE_EXPANDED_CHECK_LIST);
  };

  const handleSaveShareFile = (current: Partial<FileSharingValue>) => {
    setFileSharing(current as FileSharingValue);
  }

  const commonLoading = loading || loadingMessage;
  const commonAction = !multiSelect && !isShareProduct && !isSingleShareProduct && !isFileSharing && !isVehicleOrder && !isShareCode;

  return (
    <ContentPreviewContext.Provider
      value={contentPreviewContextValue}
    >
      <Container flex={1}>
        <PreviewHeader
          actionName={actionState.title || title}
          onBack={actionState.onBack || undefined}
          right={actionState.toolbarRight || undefined}
        />
        <Background flex={1}>
          {commonLoading && (
            <Flex flex={1} align="center" justify="center">
              <Spinner />
            </Flex>
          )}

          {Object.keys(content).length > 0 && !commonLoading && (
            <Container flex={1}>
              {(commonAction) && (
                <Flex padding={24} flex={1} style={{ backgroundColor: Colors.SUBTILE }}>
                  <Header3 text={translate('preview')} style={{ marginBottom: 16 }} />
                  <PreviewImage
                    source={{ uri: content.preview_image }}
                    resizeMode={FastImage.resizeMode.contain}
                  />
                </Flex>
              )}
              {
                isFileSharing && <FileSharing value={fileSharing} onSave={handleSaveShareFile} />
              }

              {
                isVehicleOrder && <VehicleOrder value={fileSharing} onSave={handleSaveShareFile} />
              }

              {
                isShareCode && <ShareCode config={form_config} onChange={(e) => setShareCodeValue(e)} />
              }

              {
                multiSelect && (
                  <ListContainer bundles={bundles}>
                    <BundleList
                      item={bundle}
                      items={bundles}
                      action={key}
                      onPress={handleBundle}
                    />
                    {
                     items && items.length > 0 && (
                        <ContentMultiSelect
                          items={items}
                          selected={selectedValue}
                          text={translate('showAllDocs')}
                          notScrollable={bundles.length > 0}
                          expandable={bundles.length > 0}
                          onChange={handleCheckList}
                          action={key}
                        />
                      )
                    }
                  </ListContainer>
                )
              }
              {
                (isShareProduct || isSingleShareProduct) && (
                  <Flex flex={1}>
                    <ViewInventoryStack
                      action={key}
                      isShareProduct
                      defaultFilter={isPureCars ? { new: 'used' } : {}}
                      showSelectButton={!isSingleShareProduct}
                    />
                  </Flex>
                )
              }
              <ShareModal
                visible={visible}
                message={formatedMessage}
                onSharePress={processSharing}
                leadId={activeOpportunity.id}
                onClose={() => setVisible(false)}
                onOpen={() => setVisible(true)}
                contacts={contact.people ? [contact.people, ...peopleList] : []}
              />
              {
                shouldRenderPreviewActions() && (
                  <CXPreviewActions
                    onOpen={onOpenInAppBrowser}
                    onShare={onShare}
                    isOpenLoading={isOpenLoading}
                    isShareLoading={isShareLoading}
                    disabled={isShareCode && !shareCodeValue}
                  />
                )
              }

              <Toast />
            </Container>
          )}
        </Background>
      </Container>
    </ContentPreviewContext.Provider>
  );
};

const mapStateToProps = ({ auth }: RootState) => ({
  loggedUser: auth.user,
  activeRooftop: auth.store,
  defaultLanguage: 'en',
});

export default connect(mapStateToProps, null)(ContentPreview);
