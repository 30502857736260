import Config from 'react-native-config';

export const {
  KANVAS_AUTH = '',
  KANVAS_API = '',
  DM_ELASTIC_URL = '',
  ELASTIC_URL = '',
  CONTENT_URL = '',
  SENTRY_KEY = '',
  SENTRY_ORG = '',
  SENTRY_PROJECT = '',
  ONE_SIGNAL_APP_ID = '',
  CODE_PUSH_DEPLOYMENT_KEY = '',
  AMPLITUDE_KEY = '',
  PUBLISHERS_URI = '',
  SALESASSIST_URL = '',
  ACTION_ENGINE_FILE_VIEWER = '',
  ACTION_ENGINE = '',
  PUSHER_API_KEY = '',
  KANVAS_PUSHER_API_KEY = '',
  ENVIROMENT = '',
  KANVAS_GRAPHQL = '',
  BRUTAL_API = '',
  DYNAMSOFT_LICENSE = '',
  APP_VERSION = '',
  SLACK_WEB_HOOK,
  SLACK_API_URL = '',
  SLACK_CHANNEL_ID = '',
  SCANDIT_ANDROID = '',
  SCANDIT_IOS = '',
  LEAD_SYSTEM_MODULE_UUID = '',
  KANVAS_ADMIN_KEY = '',
} = Config;
