import { useSelector } from "react-redux";

import { DEFAULT_COMPANY_INFO_KEY } from "domain/shared/constants";
import ActionEngineService, { IEleadsSyncParam } from "domain/shared/services/actionEngine-service";
import { Opportunity } from "domain/shared/types/opportunity";
import getToken from 'domain/shared/utils/auth/async-storage';
import wait from "domain/shared/utils/wait";
import { RootState } from "domain/shared/store";

async function getCompanyUUID() {
  const defaultCompanyUUID = await getToken(DEFAULT_COMPANY_INFO_KEY);
  return defaultCompanyUUID.default_company_uuid;
}

const Eleads = {
  buildRequestData(opportunity: Opportunity, companyUUID: string): IEleadsSyncParam {
    const { link_sources } = opportunity;
    const { lead_id, person_id } = link_sources.e_leads;
  
    return {
      company: companyUUID,
      dealId: lead_id,
      personId: person_id,
      opportunity: {
        name: opportunity.people.name,
        firstname: opportunity.firstname,
        lastname: opportunity.lastname,
        phones: [opportunity.people.phone],
        emails: [opportunity.people.email],
        birthday: ''
      },
    };
  },

  async getOpenLead(opportunityId: string, requestData: IEleadsSyncParam): Promise<Opportunity | undefined> {
    try {
      const item = await ActionEngineService.syncELeads(requestData);
  
      if (!Array.isArray(item)) return item.data as Opportunity;
  
      const opportunity = item.find((v) => v.id === opportunityId)?.data as Opportunity;
      return opportunity;
    } catch {
      return undefined;
    }
  },
  
  async getCloseLead(opportunityId: string, requestData: IEleadsSyncParam): Promise<Opportunity | undefined> {
    try {
      const item = await ActionEngineService.syncELeadsClosed(requestData);
      const opportunity = item.find((v) => v.id === opportunityId)?.data as Opportunity;
      return opportunity;
    } catch {
      return undefined;
    }
  }
}

export default function wallSyncLead(opportunity: Opportunity): () => Promise<Opportunity> {
  const crm = useSelector((state: RootState) => state.settings.companySettings?.crm_provider);
  
  async function vin(): Promise<Opportunity> {
    const { VIN_SOLUTION_LEADS } = opportunity.custom_fields;

    const companyUUID = await getCompanyUUID();
    await ActionEngineService.syncVinsolution(VIN_SOLUTION_LEADS.toString(), companyUUID);
    await wait(1000);

    const items = await ActionEngineService.getVinOpportunity(VIN_SOLUTION_LEADS);
    return items[0];
  }

  async function eleads() {
    const companyUUID = await getCompanyUUID();
    const requestData = Eleads.buildRequestData(opportunity, companyUUID);
    const id = opportunity.id.toString();

    const item = await Eleads.getOpenLead(id, requestData);
    if (item) return item;

    const closedItem = await Eleads.getCloseLead(id, requestData);
    if (closedItem) return closedItem;

    return opportunity
  }

  return function() {
    const execute: Record<string, () => Promise<Opportunity>> = {
      'vinsolutions': vin,
      'eleads': eleads,
      'default': () => Promise.resolve(opportunity)
    };

    return execute[crm?.toLowerCase() || 'default']?.() || execute['default']();
  }
}