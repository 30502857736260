// TODO: refactor this constant and move to model

export const BROWSE_INVENTORY_SA = 'dac.BrowseInventory.SA';
export const CONTENT_PREVIEW = 'dac.ContentPreview';
export const CHAT = 'dac.CHAT';
export const CREATE_SESSION = 'dac.CreateSession';
export const SCANNER = 'dac.SA.SCANNER';
export const ID_SCANNER = 'dac.IdScanner';
export const CLOSED_SESSIONS = 'dac.ClosedSessions';
export const CONTACT_LIST = 'dac.ContacList';
export const VIEW_INVENTORY = 'dac.ViewInventory';
export const ACTIVE_SESSION = 'dac.ActiveSession';
export const CREATE_CONTACT = 'dac.CreateContact';
export const VEHICLE_INFO_GALLERY = 'dac.VehicleInformationGallery';
export const EDIT_CONTACT = 'dac.EditContact';
export const VEHICLE_INFO_SA = 'dac.VehicleInformationSA';
export const ENGAGEMENT = 'dac.Engagement';
export const CONTACT_FOUND_LIST = 'dac.ListContactFound';
export const EDIT_SESSION = 'dac.EditSession';
export const CONTACT_MODAL = 'Dac.contact.modal';
export const BROWSE_BANKS = 'dac.BrowseBanks';
export const LOGIN = 'dac.Login';
export const HOME = 'dac.Home';
export const APP = 'dac.SA';
export const FILTERS = 'dac.Filters';
export const FULL_CARROUSEL = 'dac.FullCarrousel';

export const SIDE_MENU = 'dac.SideMenu';
export const SCAN_VIN = 'dac.ScanVin';
export const BROWSE_INVENTORY_DM = 'dac.BrowseInventory.DM';
export const BROWSE_INVENTORY_IH = 'dac.BrowseInventory.IH';
export const VIEW_VEHICLE_DM = 'dac.ViewVehicle.DM';
export const VIEW_VEHICLE_IH = 'dac.ViewVehicle.IH';
export const CAMERA_SCAN = 'dac.CameraScan';
export const MODAL_VALIDATION = 'dac.ModalValidation';
export const LOADING_OVERLAY = 'dac.LoadingOverlay';

export const DYNAMIC_MODAL = 'dac.DynamicModal';
export const BASIC_INFO = 'dac.BasicInfo';
export const VEHICLE_INFO = 'dac.VehicleInformation';
export const MODAL_COLLAGE = 'dac.ModalCollage';
export const SOCIAL_MEDIA_MODAL_COLLAGE = 'dac.SocialMediaCollage';
export const PHOTOS_PICKER = 'dac.PhotosPicker';
export const PROFILE = 'dac.Profile';
export const ACCOUNT = 'dac.Account';
export const PREFERENCES = 'dac.Preferences';
export const CONTACT = 'dac.Contact';
export const EDIT_GENERAL = 'dac.EditGeneral';
export const EDIT_SECURITY = 'dac.EditSecurity';
export const EDIT_SHARING = 'dac.EditSharing';
export const EDIT_SOCIAL_MEDIA = 'dac.EditSocialMedia';
export const DEALER_LOCATION_PREVIEW = 'dac.DealerLocationPreview';
export const DIGITAL_CARD_PREVIEW = 'dac.DigitalCardPreview';
export const MY_PAGE = 'dac.MyPage';
export const DIGITAL_CARD = 'dac.DigitalCard';
export const EDIT_PROFILE = 'dac.EditProfile';
export const ADD_PHOTOS = 'dac.AddPhotos';
export const CAMERA_VIEWER = 'dac.CameraViewer';
export const PHOTO_VIEWER = 'dac.PhotoViewer';
export const PHOTOS_LOADER = 'dac.PhotosLoader';
export const CHANGE_APP = 'dac.ChangeScreen';
export const SOCIAL_MEDIA_PHOTOS_LOADER = 'dac.SocialMediaPhotosLoader';
export const FILTERS_DETAILS = 'dac.Filters.Details';
export const VEHICLE_SHARE_MENU = 'dac.Share.Menu';
export const FORM = 'dac.Form';
export const IN_APP_BROWSER = 'dac.InAppBrowser';
export const IN_APP_BROWSER_WALL = 'dac.InAppBrowserWall';
export const CAMERA = 'dac.CAMERA';
export const ADD_OPPORTUNITY = 'ADD_OPPORTUNITY';
export const POSIBLEMATCHES = 'dac.PosibleMatches';
export const ADD_COBUYER = 'dac.AddCobuyer';

// Verification Forms
export const INSURANCE_FORM = 'dac.InsuranceForm';
export const PAYOFF_ID_FORM = 'dac.PayoffIdForm';
export const SOLD_CARD_FORM = 'dac.SoldCardForm';
export const MILEAGE_CONFIRMATION_FORM = 'dac.MileageConfirmationForm';

// TWO FACTOR AUTHENTICATION
export const SET_UP_2_FACTOR = 'sa.auth.2fac.setup';

export default {
  LOGIN: 'dac.Login',
};
