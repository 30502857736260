import React, { useContext } from "react";
import { Flex, Spacer } from "@kanvas/andromeda";
import { useEffectOnce } from "react-use";
import { ActivityIndicator } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useNavigation } from "@react-navigation/native";
import FastImage from "react-native-fast-image";
import styled from "styled-components/native";

import wallSyncLead from "model/use-cases/wall-sync-lead";

import { RootState } from "domain/shared/store";
import { setActiveOpportunity } from "domain/shared/store/slice/opportunity";
import { OpportunityScreens } from "domain/opportunity-wall";
import { CXTabBarContext } from "components/organisms/CXTabBar/cx-tab-bar-context";

import { Header2 } from "components/molecules/text";
import colors from "theme/colors";

import Initial from '../../../../assets/images/search-loading/initial.png';
import { FONTS } from "theme/typography";

export default function SyncWithCrm() {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const context = useContext(CXTabBarContext);

  const lead = useSelector((state: RootState) => state.opportunity.activeOpportunity!);

  const sync = wallSyncLead(lead);

  useEffectOnce(() => {
    context.setTabBarVisible(false);

    sync().then((lead) => {
      dispatch(setActiveOpportunity(lead));
      // @ts-ignore
      navigation.navigate(OpportunityScreens.WALL);
    })
  });

  return (
    <Root>
      <TitleBox>
        <Spacer size={30} />
        <Image source={Initial} />
        <Title align="center" text={"Syncing lead with CRM..."}/>
      </TitleBox>

      <ActivityIndicator size="large" color={colors.ORANGE} />
      <Spacer size={15} />
    </Root>
  );
}

const Root = styled(Flex)`
  flex: 1;
  padding: 16px 16px;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  background-color: ${colors.WHITE};
`;

const Image = styled(FastImage)`
  width: 70px;
  height: 70px;
`;

const TitleBox = styled(Flex)`
  align-items: center;
  gap: 20px;
`;

const Title = styled(Header2)
.attrs((props) => ({ ...props, numberOfLines: 2 }))`
  min-height: 48px;
  width: 100%;
  font-family: ${FONTS.SFProText};
`;

