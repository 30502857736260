import React from "react";
import { Flex } from "@kanvas/andromeda";
import { ListRenderItemInfo } from "react-native";
import styled from "styled-components/native";
import Icon from 'react-native-vector-icons/MaterialIcons';

import { RectButton } from "components/atoms/touchable";

import colors from "theme/colors";

import { Body3 } from "../text";

export interface Item {
  label: string;
  value: string | number;
}

interface Props {
  selected?: boolean;
  item: ListRenderItemInfo<Item>;
  onSelect?: (item: Item) => void;
}

export default function CheckboxItem(props: Props) {
  const { onSelect, selected } = props;
  const { item } = props.item;

  const iconName = selected ? 'check-box' : 'check-box-outline-blank';
  const iconColor = selected ? colors.DARK_ORANGE : colors.SECONDARY_TEXT;

  return (
    <Button onPress={() => onSelect?.(item)}>
      <Container>
        <Icon name={iconName} color={iconColor} size={24} />
        <Body3 text={item.label?.trim()} />
      </Container>
    </Button>
  );
}

const Container = styled(Flex)`
  gap: 5px;
  padding: 16px;
  flex-direction: row;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.DIVIDER};
`;

const Button = styled(RectButton)`
  width: 100%;
`;