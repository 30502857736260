import moment from 'moment';
import axios from './axios';
import { gql } from "@apollo/client";
import client from '../graphql/clientAdmin';

type FormOfacData = {
  error: boolean;
  matches: any[];
  sourcesUsed: any[];
}

type FormData = {
  file: string;
  ofac: FormOfacData;
}

type OfacResponse = {
  data: {
    data: { form: FormData; }
    link_preview: string;
    status: string;
    verb: string;
  }
  id: number;
  uuid: string;
}

type PullCreditResponse = {
  message_id: number;
  iframe_url_digital_jacket: string;
};

export default class LeadsService {
  static async toggleChrono(contact: any, started?: boolean): Promise<any> {
    const date = moment();

    const body = {
      is_chrono_running: started,
      title: contact?.people?.name,
      chrono_start_date: started ? date : null,
    };

    const { data } = await axios.put(`leads/${contact.id}`, body, {
      headers: { 'Content-Type': 'application/json' },
    });

    return data;
  }

  static async Ofac(leadId: string): Promise<OfacResponse> {
    const response = await axios.post<OfacResponse>(`/leads/${leadId}/messages/ofac/action`);
    return response.data;
  }

  static async PullCredit(leadId: string, options: string): Promise<PullCreditResponse> {
    const response = await client.mutate<{ runWorkflowFromEntity: PullCreditResponse[] }>({ 
      mutation: RunWorkflowFromEntity,
      fetchPolicy: 'network-only',
      variables:  {leadId, params: {pull: "700credit", provider: options}}
    });

    const res = response.data?.runWorkflowFromEntity[0];

    // Change check for message.id after fix
    if (!!response.errors || !res?.message_id) {
      throw new Error('Error pulling credit');
    }

    return res;
  }
}

const RunWorkflowFromEntity = gql`
  mutation RunWorkflowFromEntity($leadId: ID!, $params: Mixed!) {
    runWorkflowFromEntity(
      input: {
        entity_namespace: "lead"
        entity_id: $leadId
        action: "pull"
        params: $params
      }
    )
  }
`;
