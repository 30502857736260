import { useEffectOnce } from "react-use";
import { Flex } from "@kanvas/andromeda";
import styled from "styled-components/native";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useDispatch } from "react-redux";

import colors from "theme/colors";
import Lead from "model/data/lead";
import LeadHubPresenter from "model/presenters/lead-hub.presenter";

import { LeadHudLoadingRef } from "components/organisms/lead-hud-loading";

import { ScannedData } from "domain/barcode-scanner/types";
import LeadList from "domain/lead-hub/components/lead-list";
import ListHeader from "domain/lead-hub/components/list-header";
import AddLeadButton from "domain/opportunity-list/components/add-lead-button";
import { OpportunityScreens } from 'domain/opportunity-list/screens';
import { OpportunityScreens as OS } from 'domain/create-opportunity';
import { sendAmplitudeEvent } from "domain/shared/utils/amplitude";
import { OPENING_OPPORTUNITY } from "domain/shared/types/amplitude-event";
import { setActiveOpportunity } from "domain/shared/store/slice/opportunity";
import { Opportunity } from "domain/shared/types/opportunity";
import { ACTIVE_SESSION } from "domain/shared/screens";
import { IS_WEB } from "domain/shared/constants";
import crmLinking from "domain/lead-hub/use-cases/crm-linking";

function useLeadHubSearch() {
  const [items, setItems] = useState<Lead[]>([]);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(false);

  const search = useRef('');
  const lead = useRef<ScannedData | null>(null);
  const loader = useRef<LeadHudLoadingRef>(null);
  
  const navigation = useNavigation();
  const { params } = useRoute();
  const dispatch = useDispatch();

  const presenter = useMemo(() => new LeadHubPresenter({
    get: (items) => {
      setItems([...items]);
      setLoading(false);
    },
    error: () => console.log('error'),
    notFound: () => loader.current?.notFound(),
    limitReach: () => setLimit(true),
  }), []);

  const clear = (fn: VoidFunction, text = '') => {
    setItems([]);
    setLoading(true);
    setLimit(false);
    search.current = text;

    fn();
    loader.current?.reset();
  }

  const handleLeadPress = (lead: Lead) => {
    const { title, id, uuid, link_sources } = lead.backendEntity;

    sendAmplitudeEvent(OPENING_OPPORTUNITY, {
      event: OPENING_OPPORTUNITY,
      lead_title: title,
      lead_id: id,
      lead_uuid: uuid,
    });

    dispatch(setActiveOpportunity(lead.backendEntity as unknown as Opportunity));
    crmLinking(lead);

    // @ts-ignore
    navigation.navigate(OpportunityScreens.WALL, { screen: OpportunityScreens.SYNC_LEAD });
  };

  const handleAdd = () => {
    // @ts-ignore
    navigation.navigate(OpportunityScreens.CREATE, {
      screen: OS.ADD,
      params: { opp: lead.current, type: 'lead' },
    });
  }

  const handleScan = (text: string, currentLead: ScannedData) => {
    search.current = text;
    lead.current = currentLead;
    clear(() => presenter.search(text), text);
  };

  const handlePagination = () => {
    if (!search.current.length) return;
    if (loading) return;
    setLoading(true);
    presenter.next();
  }

  const handleRefresh = () => clear(() => presenter.refetch());

  const handleSearch = (text: string) => clear(() => presenter.search(text), text);

  const handleClear = () => {
    setItems([]);
    setLoading(false);
    setLimit(false);
    search.current = '';
    presenter.clear();
    loader.current?.reset();
  };

  useEffect(() => {
    if (!IS_WEB) return;
    if (!params) return;
    const { text } = params as any;
    handleSearch(text);
  }, [params]);

  useEffectOnce(() => () => handleClear());


  return {
    model: {
      items,
      search,
      loader,
      loading,
      limit,
    },
    operations: {
      handleAdd,
      handleSearch,
      handlePagination,
      handleLeadPress,
      handleRefresh,
      handleScan,
      handleClear,
    }
  }
}

export default function LeadHubSearch() {
  const { model, operations } = useLeadHubSearch();
  const navigation = useNavigation();

  const { items, search, loader, limit, loading } = model;
  const { handleAdd, handleSearch, handlePagination, handleLeadPress, handleRefresh, handleScan, handleClear } = operations;

  return (
    <Container>
      <ListHeader 
        onScan={handleScan}
        onClear={handleClear}
        onSearch={handleSearch}
        onBack={navigation.goBack}
      />

      <LeadList 
        items={items}
        limit={limit}
        loader={loader}
        loading={loading}
        search={search.current}
        onAdd={handleAdd}
        onPaginate={handlePagination}
        onLeadPress={handleLeadPress}
        onRefresh={handleRefresh}
      />

      <AddLeadButton onAdd={handleAdd} />
    </Container>
  );
}



const Container = styled(Flex)`
  flex: 1;
  background-color: ${colors.WHITE};
`;
