import React from "react";
import { Flex } from "@kanvas/andromeda";
import { ReactNode, useMemo } from "react";
import styled from "styled-components/native";
import { ListRenderItemInfo } from "react-native";
import Icon from 'react-native-vector-icons/MaterialIcons';

import colors from "theme/colors";
import Task, { Status } from "model/data/task";

import { Body3 } from "../text";

interface Props {
  info: ListRenderItemInfo<Task>;
  TaskButton: ReactNode;
} 

export default function TaskItem(props: Props) {
  const { item } = props.info;

  const status = useMemo(() => {
    let status: Record<any, any> = { color: colors.DISABLED, name: 'check-circle-outline' }

    if (item.status === Status.NO_APPLICABLE) {
      return { color: colors.TEXT_PLACEHOLDER, name: 'check-circle', text: colors.DISABLED };
    }

    if (item.status === Status.COMPLETED) {
      status.color = colors.POSITIVE;
      status.name = 'check-circle';
    }

    if (item.config.inactive) {
      status.text = colors.DISABLED;
    }

    return status;
  }, []);

  return (
    <Root row align='center' justify='space-between'>
      <Flex row align='center' gap={8}>
        <Icon name={status.name} color={status.color} size={24} />
        <Body3 link text={item.title} color={status.text || colors.PRIMARY_TEXT} />
      </Flex>

      {props.TaskButton}
    </Root>
  );
}

const Root = styled(Flex)`
  width: 100%;
  padding: 0 16px;
  min-height: 40px;
`;