import React from "react";
import { ListRenderItemInfo } from "react-native";
import styled from "styled-components/native";
import Icon from 'react-native-vector-icons/MaterialIcons';

import Task, { Status, Type } from "model/data/task";

import colors from "theme/colors";
import { IS_WEB } from "domain/shared/constants";

import { Button2 } from "../text";

interface Props {
  info: ListRenderItemInfo<Task>;
  onPress?: (item: any, redo?: boolean) => void;
}

export default function TaskButton(props: Props) {
  const { info, onPress } = props;
  const { item } = info;

  const textColor = item.config.disabled || item.config.inactive ? colors.DISABLED_BUTTON_TEXT : colors.TEXT_ORANGE_LINK;
  const isPullCredit = item.type === Type.PULL_CREDIT;

  const handlePress = (redo = false) => onPress?.(item, redo);

  if (item.status === Status.COMPLETED) {
    const icon = isPullCredit ? 'link' : 'attach-file';
    const disabled = isPullCredit ? item.config.iframe_url_signed === undefined : item.config.messageUuid === undefined;

    return (
      <ButtonsContainer>
        {isPullCredit && (
          <Redo activeOpacity={.5} onPress={() => handlePress(true)}>
            <Icon name='replay' color={colors.TEXT_ORANGE_LINK} size={24} />
          </Redo>
        )}

        <ReviewFile activeOpacity={.5} onPress={() => handlePress()} disabled={disabled}>
          <Icon name={icon} color={disabled ? colors.DISABLED : colors.WHITE} size={16} />
        </ReviewFile>
      </ButtonsContainer>
    );
  }

  return (
    <Start activeOpacity={.5} onPress={() => handlePress()} disabled={item.config.disabled || item.config.inactive}>
      <Button2 text='Start' color={textColor} />
    </Start>
  );
}

const ButtonsContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;

const CommonButton = styled.TouchableOpacity<{ disabled?: boolean }>`
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  background-color: ${({ disabled }) => disabled ? colors.DISABLED_BUTTON_BACKGROUND : colors.WHITE};
  border: 1px solid ${colors.BUTTON_BORDER};;
  box-shadow: 0px -4px 16px ${colors.BUTTON_BORDER};
  ${IS_WEB && `box-shadow: 0px -4px 16px ${colors.BUTTON_BORDER};`};
`;

const Start = styled(CommonButton)`
  width: 70px;
  height: 40px;
  padding: 0 8px;
`;

const Redo = styled.TouchableOpacity`
  width: 50px;
  height: 40px;
  align-items: center;
  justify-content: center;
`;

const ReviewFile = styled(CommonButton)`
  width: 70px;
  height: 40px;
  background-color: ${({ disabled }) => disabled ? colors.DISABLED_BUTTON_BACKGROUND : colors.ORANGE};
`;