/* eslint-disable @typescript-eslint/no-unused-vars */
// Molecules
import React, { useMemo, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Flex, useDeviceImage } from '@kanvas/andromeda';
import styled from 'styled-components/native';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { TextTransform } from 'react-native-localized-text';
import { getBottomSpace } from 'react-native-iphone-x-helper';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RouteProp } from '@react-navigation/native';

// Themes
import colors from 'theme/colors';

// Molecules
import PreviewHeader from 'components/molecules/preview-header';
import { translate } from 'locales';
import { FormLabel, InputWithLabel as TextInputWithLabel } from 'components/molecules/input-with-label';
import UploadInput from 'components/molecules/upload-input';

// Atoms
import ButtonWithIcon from 'components/atoms/button-with-icon';
import DropDown from 'components/atoms/dropdown-input';

// Organisms
import VehicleCondition from 'components/organisms/vehicle-condition';

// Types
import { UploadFile } from 'domain/shared/types/file.interface';
import { LeadAction } from 'domain/shared/types/lead-action';
import { IS_WEB } from 'domain/shared/constants';

// Hooks
import useSubmitForm from 'model/hooks/use-submit-form';
import usePickFile from '../../content-preview/hooks/use-pick-file';

type SetFieldValue = (field: string, value: any, shouldValidate?: boolean | undefined) => void;

type ParamList = {
  Any: { action: LeadAction, onGoBack: VoidFunction }
};

interface IProps {
  route: RouteProp<ParamList, 'Any'>;
  navigation: NativeStackNavigationProp<ParamList, 'Any'>;
}

interface Form {
  vehicle_type_id: number;
  stock: string;
  vin: string;
  mileage: string;
  uploadWindowSticker: UploadFile;
  tradein_status: string;
  tradein_stock: string;
  tradein_vin: string;
  tradein_mileage: string;
}

const Container = styled.View`
  flex: 1px;
  background-color: ${colors.WHITE};
`;

const Content = styled(KeyboardAwareScrollView)`
  padding: 20px;
`;

const ButtonContainer = styled(Flex)`
  margin-bottom: ${getBottomSpace()}px;
  padding-vertical: 16px;
  border-top-width: 1px;
  border-top-color: ${colors.BORDER_COLOR};
  padding-bottom: 60px;
`;

const InputContainer = styled(Flex)`
  background-color:${colors.WHITE};
  height: 70px;
  margin-vertical:1px;
`;

const initialValues = {
  vehicle_type_id: 0,
  vin: '',
  stock: '',
  mileage: '',
  uploadWindowSticker: '',
  tradein_stock: '',
  tradein_vin: '',
  tradein_mileage: '',
};

const options = [
  translate('photoGallery', TextTransform.CAPITAL),
  translate('camera', TextTransform.CAPITAL),
  translate('cancel', TextTransform.CAPITAL),
];

const checkOptions = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
]

const MileageConfirmationForm = (props: IProps) => {

  // Props
  const { navigation, route } = props;
  const { action, onGoBack } = route.params;

  // State
  const [loading, setLoading] = useState(false);
  const [soldCardType, setSoldCardType] = useState(0);
  const [tradeInStatus, setTradeInStatus] = useState<string>();

  // Hooks
  const { submit, separateFilesFromFields } = useSubmitForm(action);
  const { handleUploadFilePromise } = usePickFile();

  const { getImageFromDevice } = useDeviceImage({
    width: 300,
    height: 300,
    forceJpg: true,
    multiple: false,
    mediaType: 'photo',
    includeBase64: true,
  });

  const onSubmit = async (values: Form) => {
    try {
      setLoading(true);
      

      const { files, form } = separateFilesFromFields(values);

      const fileList = Object.values(files);
      const submitted = await submit(form, fileList);
      if (!submitted) return;
      onGoBack?.();
      navigation.goBack();
    } finally {
      setLoading(false);
    }
  };

  const handleUploadFile = async (inputName: string, setFieldValue?: SetFieldValue) => {
    if (IS_WEB) {
      const file = await handleUploadFilePromise();
      setFieldValue?.(inputName, file);
      return;
    }

    const image = await getImageFromDevice(options);

    if (!image) return;

    const name = image.path.split('/').slice(-1).join();

    const file = {
      uri: image.path,
      type: image.mime,
      file_type: image.mime,
      name: image.filename || name,
    };
    setFieldValue?.(inputName, file);
  };

  const handleReset = (inputName: string, setFieldValue?: SetFieldValue) => {
    setFieldValue?.(inputName, '');
  };

  const config = useMemo(() => {
    if (!action) return [];
    if (action.form_config === null) return [];
    const config = JSON.parse(action.form_config) as Record<string, unknown>;
    return config['hidden'] as string[];
  }, [action]);

  const validationSchema = useMemo(() => {
    const uploadWindowSticker = soldCardType === 0 ? Yup.mixed().required() : Yup.string();

    const tradeInRequired = tradeInStatus === 'Yes' ? Yup.string().required() : Yup.string();

    const validation = Yup.object().shape({
      vehicle_type_id: Yup.string().required(),
      vin: Yup.string().required(),
      stock: Yup.string().required(),
      mileage: Yup.string().required(),

      uploadWindowSticker,

      tradein_status: Yup.string().required(),
      tradein_stock: tradeInRequired,
      tradein_vin: tradeInRequired,
      tradein_mileage: tradeInRequired,
    });

    return validation;
  }, [config, soldCardType, tradeInStatus]);

  return (
    <Container>

      <PreviewHeader
        actionName={translate('mileageConfirmation', TextTransform.CAPITAL)}
        onBack={() => navigation.goBack()}
      />

      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues as any}
        validationSchema={validationSchema}
      >
        {
          ({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            validateForm,
            values,
            errors,
            touched,
          }) => (

            <Content
              showsVerticalScrollIndicator={false}
              extraScrollHeight={100}
            >

              <InputContainer>
                <FormLabel
                  required
                  validate={errors.vehicle_type_id}
                  text={translate('vehicleCondition', TextTransform.CAPITALIZE)}
                />
                <VehicleCondition
                  onChange={(item) => setFieldValue('vehicle_type_id', item.value)}
                  onItemChange={(item) => setSoldCardType(item.value)}
                  value={values.vehicle_type_id}
                />
              </InputContainer>

              <TextInputWithLabel
                label={`${translate('stock', TextTransform.CAPITALIZE)} #`}
                onChangeText={handleChange('stock')}
                onBlur={handleBlur('stock')}
                value={values?.stock}
                errors={touched.stock ? errors.stock : ''}
                required
              />

              <TextInputWithLabel
                label={`${translate('vin', TextTransform.CAPITALIZE)} #`}
                placeholder="0"
                onChangeText={handleChange('vin')}
                onBlur={handleBlur('vin')}
                value={values?.vin}
                errors={touched.vin ? errors.vin : ''}
                keyboardType='numeric'
                required
              />

              <TextInputWithLabel
                label={`${translate('mileage', TextTransform.CAPITALIZE)}`}
                placeholder="0"
                onChangeText={handleChange('mileage')}
                onBlur={handleBlur('mileage')}
                value={values?.mileage}
                errors={touched.mileage ? errors.mileage : ''}
                keyboardType='numeric'
                required
              />

              {values.vehicle_type_id === 0 && (
                <UploadInput
                  required
                  value={values.uploadWindowSticker}
                  onPress={() => handleUploadFile('uploadWindowSticker', setFieldValue)}
                  onReset={() => handleReset('uploadWindowSticker', setFieldValue)}
                  label={translate('uploadWindowSticker', TextTransform.CAPITAL)}
                  buttonLabel={translate('upload', TextTransform.CAPITAL)}
                  Icon={<Icon name="add" size={24} color={colors.DARK_ORANGE} />}
                  styles={styles.uploadButtonContainer}
                />
              )}

              <Flex style={{ marginBottom: 2 }}>
                <FormLabel text={translate('tradeInStatus.status', TextTransform.CAPITAL)} required />
                <DropDown
                  items={checkOptions}
                  placeHolder=''
                  value={values.tradein_status}
                  onSelectItem={(item: any) => {
                    setFieldValue('tradein_status', item?.value);
                    setTradeInStatus(item?.value);
                  }}
                  errorsRefs={touched.tradein_status ? errors.tradein_status : ''}
                />
              </Flex>

              {values.tradein_status === 'Yes' && (
                <>
                  <TextInputWithLabel
                    label={`${translate('tradeInStatus.stock', TextTransform.CAPITALIZE)} #`}
                    onChangeText={handleChange('tradein_stock')}
                    onBlur={handleBlur('tradein_stock')}
                    value={values?.tradein_stock}
                    errors={touched.tradein_stock ? errors.tradein_stock : ''}
                    required
                  />

                  <TextInputWithLabel
                    label={`${translate('tradeInStatus.vin', TextTransform.CAPITALIZE)} #`}
                    placeholder="0"
                    onChangeText={handleChange('tradein_vin')}
                    onBlur={handleBlur('tradein_vin')}
                    value={values?.tradein_vin}
                    errors={touched.tradein_vin ? errors.tradein_vin : ''}
                    keyboardType='numeric'
                    required
                  />

                  <TextInputWithLabel
                    label={`${translate('tradeInStatus.mileage', TextTransform.CAPITALIZE)}`}
                    placeholder="0"
                    onChangeText={handleChange('tradein_mileage')}
                    onBlur={handleBlur('tradein_mileage')}
                    value={values?.tradein_mileage}
                    errors={touched.tradein_mileage ? errors.tradein_mileage : ''}
                    keyboardType='numeric'
                    required
                  />
                </>
              )}

              <ButtonContainer>
                <ButtonWithIcon
                  loading={loading}
                  text={translate('submit', TextTransform.CAPITAL)}
                  onPress={async () => {
                    const errorList = await validateForm();
                    if (errorList) {
                      console.log('errorList', errorList);
                    }
                    handleSubmit();
                  }}
                />
              </ButtonContainer>
            </Content>
          )
        }
      </Formik>

    </Container>
  );
};

const styles = StyleSheet.create({
  uploadButtonContainer: {
    marginVertical: 10,
  },
});

export default React.memo(MileageConfirmationForm);

