/* eslint-disable import/prefer-default-export */
import {
  CLOSED_SESSIONS,
  CREATE_SESSION,
  EDIT_PROFILE,
  IN_APP_BROWSER,
  IN_APP_BROWSER_WALL,
  ID_SCANNER,
  SCANNER,
  ADD_COBUYER,
} from 'domain/shared/screens';

export const OpportunityScreens = {
  LIST: CLOSED_SESSIONS,
  SEARCH: 'sa.search',
  WALL: 'OPP_WALL_STACK',
  SYNC_LEAD: 'sa.sync-lead',
  CREATE: CREATE_SESSION,
  EDIT_PROFILE,
  IN_APP_BROWSER,
  IN_APP_BROWSER_WALL,
  SCANNER,
  ID_SCANNER,
  CHROME_EXTENSION: 'APP.CHROME_EXTENSION',
  ADD_COBUYER,
};
